import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { CustomerService } from '@fundo/app/services/customer.service';
import { PixelTrackingInjectorService } from '@fundo/app/services/pixel-tracking-injector.service';
import { NavigationService } from '@shared/services/navigation.service';
import { ButtonModule } from '@ui/components/button/button.module';
import { MdTextComponent } from '@ui/components/md-text/md-text.component';

import { DialogInfoComponent } from './dialog-info.component';
import { SpinnerModule } from '../../components/spinner/spinner.module';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';

@NgModule({
  declarations: [DialogInfoComponent],
  imports: [
    CommonModule,
    // Components
    ButtonModule,
    MdTextComponent,
    SpinnerModule,
    // Shared
    NgOptimizedImage,
    // Material
    MatDialogModule,
  ],
  exports: [DialogInfoComponent],
  providers: [CustomerService, NavigationService, PixelTrackingInjectorService],
})
export class DialogInfoModule {}
