<section
  class="dialog__content"
  [ngClass]="{ content__docs: dialogConfig.isDocsInfo }">
  <ui-spinner
    *ngIf="dialogConfig.hasSpinner"
    thickness="fat"
    class="dialog__spinner"></ui-spinner>
  <img
    *ngIf="dialogConfig.image"
    class="dialog__image"
    [ngSrc]="imageSrc"
    height="80"
    width="100"
    aria-hidden="true" />

  <mat-dialog-content>
    <ui-md-text
      class="dialog__text-content"
      [markdownText]="dialogConfig.content"></ui-md-text>
  </mat-dialog-content>
  <div class="dialog__buttons">
    <ui-button
      *ngIf="dialogConfig.buttonLabel"
      size="small"
      class="dialog__button"
      [isLoading]="!!data.actionBeforeClose && isButtonLoading"
      (click)="onContinue(dialogConfig.buttonLabel)">
      {{ dialogConfig.buttonLabel }}
    </ui-button>
  </div>
</section>
