import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DialogInfoConfig, DialogInfoId } from '@fundo/app/constants/dialogs';
import { NavigationService } from '@fundo/app/shared/services/navigation.service';
import { Observable, finalize } from 'rxjs';

import {
  completeApplication,
  generalError,
  incompleteApplication,
  ssnValidationFail,
  supportingDocuments,
  verifyingInfo,
} from './content';

type ContentId = `${DialogInfoId}`;

type ContentTypeList = {
  [key in ContentId]: {
    image: string | null;
    content: any;
    buttonLabel: string | null;
    hasSpinner?: boolean;
    isDocsInfo?: boolean;
  };
};

@Component({
  selector: 'fo-dialog-info',
  templateUrl: './dialog-info.component.html',
  styleUrls: ['./dialog-info.component.scss'],
})
export class DialogInfoComponent implements OnInit, OnDestroy {
  /**
   * List of info dialogs
   */
  readonly dialogs: ContentTypeList = {
    'general-error': {
      image: 'error',
      content: generalError,
      buttonLabel: 'OK',
    },
    'ssn-validation-fail': {
      image: 'error',
      content: ssnValidationFail,
      buttonLabel: 'OK',
    },
    'incomplete-application': {
      image: null,
      content: incompleteApplication,
      buttonLabel: 'OK',
    },
    'complete-application': {
      image: 'success',
      content: completeApplication,
      buttonLabel: 'GO TO HOME PAGE',
    },
    'verifying-information': {
      image: null,
      content: verifyingInfo,
      buttonLabel: null,
      hasSpinner: true,
    },
    'supporting-documents': {
      image: null,
      content: supportingDocuments,
      buttonLabel: 'OK',
      isDocsInfo: true,
    },
    'plaid-report-expired': {
      image: 'error',
      content: `Please note that we require up-to-date financial information to proceed with your application. As it has been 3 days since your Plaid data submission, you will be redirected to the Bank Verification step to verify your bank account once again. Thank you for your cooperation.`,
      buttonLabel: 'OK',
    },
    'same-day-funding-violation': {
      image: 'error',
      content: `We've noticed that you're attempting to sign the agreement with the same day funding option. However, please note that same day funding is only applicable if the agreement is signed on the same day the option was selected before 3 pm EST. You will now be redirected back to update the funding option in order to proceed with your application.`,
      buttonLabel: 'OK',
    },
  };

  /**
   * Dialog Id defined on text component
   */
  public dialogConfig: DialogInfoConfig;

  /**
   * Loader indicator
   */
  isButtonLoading: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { type: ContentId; actionBeforeClose: Observable<any> },
    private dialogRef: MatDialogRef<DialogInfoComponent>,
    private navigationService: NavigationService,
  ) {}

  /**
   * Src image
   */
  public get imageSrc(): string {
    return `assets/img/state/${this.dialogConfig.image}.png`;
  }

  ngOnInit(): void {
    this.dialogConfig = this.dialogs[this.data.type];
    if (
      this.data.type === 'verifying-information' ||
      this.data.type === 'complete-application'
    ) {
      this.dialogRef.disableClose = true;
    }
    if (this.data.type === 'plaid-report-expired') {
      this.blockBackgroundElements();
    }
  }

  public onContinue(label: string): void {
    if (label === 'GO TO HOME PAGE') {
      this.navigationService.navigateToMainPage();
    }
    if (!!this.data.actionBeforeClose) {
      this.isButtonLoading = true;
      this.data.actionBeforeClose
        .pipe(finalize(() => (this.isButtonLoading = false)))
        .subscribe(
          (closeDialog) => closeDialog && this.dialogRef.close(),
        );
    } else {
      this.dialogRef.close();
    }
  }

  ngOnDestroy(): void {
    this.unblockBackgroundElements();
  }

  private blockBackgroundElements() {
    document
      .querySelector('app-root')
      ?.classList.add('view--blurred', 'view--blocked');
  }

  private unblockBackgroundElements() {
    document
      .querySelector('app-root')
      ?.classList.remove('view--blurred', 'view--blocked');
  }
}
